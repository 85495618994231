<template>
    <div id="stepCont" style="">
        <stepper :options="stepperOptions">
            <!-- Step 01 -->
            <div slot="step-1" class="step-content-box">
                <h3>Tell us about your store environment</h3>
                <div class="step-content-text">   
                    Select  your merchant store account type
                </div>  
                <div class="input-container">
                    <select class="form-control" id="accType" v-model="accType">
                        <option></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                    </select>
                </div>
                <button @click="goToNext()" id="stepOneNext" class="btn-primary">Next</button>
            </div>
            <!-- Step 02 -->
            <div slot="step-2" class="step-content-box">
                <h3>Tell us about your personal information</h3>
                <!-- <div class="step-content-text">   
                    Select  your merchant store account type
                </div>   -->
                <div class="input-container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="firstName"><span>*</span> First Name:</label>
                                <input type="text" class="form-control" id="firstName" v-model="firstName">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="middleName"><span>*</span> Middle Name:</label>
                                <input type="text" class="form-control" id="middleName" v-model="middleName">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="lastName"><span>*</span>Last Name:</label>
                                <input type="text" class="form-control" id="lastName" v-model="lastName">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"></div>
                     </div>    
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="email"><span>*</span> Email:</label>
                                <input type="email" class="form-control" id="email" v-model="email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="mobile"><span>*</span> Mobile:</label>
                                <input type="text" class="form-control" id="mobile" v-model="mobile">
                            </div>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="fixedLineNumber">Fixed Line Number (optional)</label>
                                <input type="text" class="form-control" id="fixedLineNumber" v-model="fixedLineNumber">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6"></div>
                    </div>
                </div> 
                <button @click="goToNext()" id="stepTwoNext" class="btn-primary">Next</button>  
            </div>
            <!-- Step 03 -->
            <div slot="step-3" class="step-content-box">
                <h3>We have sent an OTP code to the mobile no. that you entered</h3>
                <div class="step-content-text">
                    <div class="chg-phone-number">+94 77 582 585</div>
                    <a href="">Change number</a>
                </div>  
                <div class="input-container">
                   <div class="row">
                       <div class="col-lg-2 col-md-2 col-sm-2">
                           <div class="form-group">
                                <input type="text" class="form-control input-lg" id="" maxlength="1">
                            </div>
                       </div>
                       <div class="col-lg-2 col-md-2 col-sm-2">
                           <div class="form-group">
                                <input type="text" class="form-control input-lg" id="" maxlength="1">
                            </div>
                       </div>
                       <div class="col-lg-2 col-md-2 col-sm-2">
                           <div class="form-group">
                                <input type="text" class="form-control input-lg" id="" maxlength="1">
                            </div>
                       </div>
                       <div class="col-lg-2 col-md-2 col-sm-2">
                           <div class="form-group">
                                <input type="text" class="form-control input-lg" id="" maxlength="1">
                            </div>
                       </div>
                       <div class="col-lg-2 col-md-2 col-sm-2">
                           <div class="form-group">
                                <input type="text" class="form-control input-lg" id="" maxlength="1">
                            </div>
                       </div>
                       <div class="col-lg-2 col-md-2 col-sm-2">
                           <div class="form-group">
                                <input type="text" class="form-control input-lg" id="" maxlength="1">
                            </div>
                       </div>
                   </div>
                </div>
                <div class="step-content-text">
                    <div class="enter-code">Enter your code before the session expires</div>
                    <div class="rel-time">{{countdownseconds}} secs</div>
                    <div class="rec-code">Did not receive your code? <a href="">Resend</a></div>
                </div>    
            </div>
            <!-- Step 04 -->
            <div slot="step-4" class="step-content-box">
                <h3>Let's secure your account</h3>
                <div class="step-content-text"></div>  
                <div class="input-container">
                    <div class="form-group">
                        <label for="fname"><span>*</span> First Name</label>
                        <input type="text" class="form-control" id="fname" placeholder="" name="fname">
                    </div>
                    <div class="form-group">
                        <label for="lname"><span>*</span> Last Name</label>
                        <input type="text" class="form-control" id="pwd" placeholder="" name="lname">
                    </div>
                    <div class="checkbox">
                        <label><input type="checkbox" name="remember">I have read and agree to the <a href="">Terms and Conditions</a></label>
                    </div>
                </div>   
            </div>
        </stepper>
    </div>
</template>
<script>
import mixinAuth from "../../mixins/merchant/APIAuth";
import Stepper from 'vuejs-stepper'
export default {
  components: { Stepper },
  name: 'MerchantOTP',
  props: ["object"],
  mixins: [mixinAuth],
  data () {
    return {
      stepperOptions: {
        headers: [
          {title: 'Title One'},
          {title: 'Title Two'},
          {title: 'Title Three'},
          {title: 'Title Four'}
        ],
        prevText: 'Previous',
        nextText: 'Next'
      },
      accType: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      mobile: '',
      fixedLineNumber: '',
      countdownseconds: 0,
    }
  },
  watch: {
    countdownseconds: function(val) {
      if(val === 0){
        this.blockresendopt = false;
      } else {
        this.blockresendopt = true;
      }
    }
  },
  mounted(){
      this.changeSteps();
  },
  created() {
       this.init();
       this.countdownCal(120);
  },
  methods: {
    changeSteps: function() {
        var stepNodes = document.getElementsByClassName("step-header-content");
        var stepsCount = stepNodes.length;
        for(var i = 0;i<stepsCount;i++) {
            stepNodes[i].innerHTML = '';
        }
    },
    goToNext: function() {
        var nextBtns = document.getElementsByClassName("next-button");
        nextBtns[0].click();
    },
    init(){
      if (this.$route.params.object != undefined) {
        this.object = this.$route.params.object;
      } else {
        this.object.mobile= "";
      }
    },
    countdownCal(duration){
       this.countdownseconds = duration;
       let self = this;
        var downloadTimer = setInterval(function(){
          
          self.countdownseconds -= 1;
          if(self.countdownseconds <= 0){
            clearInterval(downloadTimer);
          }
          
        }, 1000);
        
    },
    resentOTP: async function() {
      try{
        let res = await this.merchantMobileResend(this.object._id);
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: "Successfully resent OTP",
          showConfirmButton: false,
          timer: 2000
        });
        this.countdownCal(120);
      }catch(error){
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: "Failed to resent OTP",
          showConfirmButton: false,
          timer: 2000
        });
      }
    },
    register(){
        this.$router.push('merchant-register');
    },
    handleLogin(){
      this.$router.push({name: 'Merchant login'});
    },
    checkForm: async function (e) {   
      e.preventDefault();

      try {
          let obj = {
            code: this.sentotp,
            _id: this.object._id
          } 
          let res = await this.merchantMobileVerify(obj);
          this.handleLogin();
          this.$swal.fire({
            position: 'center',
            type: 'success',
            title: 'Successfully verified OTP!',
            showConfirmButton: false,
            timer: 1500
          });   
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Invalid OTP - ' + error.data.message,
          showConfirmButton: false,
          timer: 2000
        })      
      }
      // let obj = {
      //   code: this.sentotp,
	    //   _id: this.object._id
      // }
      // console.log(this.sentotp);
      // console.log(obj);
      // let res = await this.merchantMobileVerify(obj);

      if(res.status == 409){
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Wrong OTP Code! Please try again.',
          showConfirmButton: false,
          timer: 1500
        })
      }
      
      if(res.status === 200){
        this.handleLogin();
      }
      
      //this.handleContinue();

    }
    
   
  }
}
</script>

<style>
#stepCont .top{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 10px;
    width: 100%;
    justify-content: center;
}
#stepCont .step-header{
    display: flex;
    flex-direction: column;
}
#stepCont .header-indicator{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#stepCont .step-header.start > .header-indicator{
    align-self: flex-end;
}
#stepCont .step-header-line{
    display: inline-flex;
    width: 100px;
    height: 0px !important;
    background-color: none !important;
    border: 1px dotted grey;
}
#stepCont .step-header-content{
    display: inline-flex;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    background-color: grey;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
#stepCont .step-header.active .step-header-content {
    background-color: #337ab7 !important;
}
#stepCont .step-header.active .step-header-line{
    border: 1px dotted #337ab7 !important;
}
#stepCont .title{
    display: none !important;
    align-self: flex-end;
    text-transform: capitalize;
    width: 100px;
    position: relative;
    right: -10%;
    justify-content: center;
}
#stepCont .step-header.start > .title{
    right: -35%;
}
#stepCont .steps-item{
    min-width: 100%;
}
#stepCont .body{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width:100%;
    flex-shrink: 0;
}
#stepCont .foot{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: right;
}
#stepCont .next-button{
    margin-left: 15px;
}
#stepCont .prev-button{
    background: none !important;
    border: 1px solid #337ab7;
    color: #337ab7;
}
#stepCont button{
    width: 110px !important;
    padding: 7px 15px;
    color: white;
    background-color: #337ab7 !important;
    border: 0;
    outline: 0;
    border-radius: 5px;
    margin-top: 30px !important;
}
#stepCont .stepper{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#stepCont .step-content-box{
    text-align: center;
    color: #2e2e2e;
}
#stepCont .step-content-box h3{
    font-size: 20px;
    margin-top: 60px;
}
#stepCont .step-content-box .step-content-text{
    font-size: 14px;
    margin-top: 40px;
}

#stepCont .step-content-box .step-content-text .chg-phone-number{
    margin-bottom: 35px;
    font-size: 16px;
    font-weight: 500;
}
#stepCont .step-content-box .step-content-text .enter-code{
    font-size: 13px;
    margin-bottom: 35px;
}
#stepCont .step-content-box .step-content-text .rel-time{
    font-size: 22px;
    color: #337ab7;
    margin-bottom: 35px;
}
#stepCont .step-content-box .step-content-text .rec-code{
    font-size: 12px;
}
/* #stepCont .input-container{
    width: 40%;
    margin: 60px auto 0 auto;
    text-align: left;
} */
#stepCont .input-container span{
    color: red;
}
#stepCont .step-content-box a{
    color: #337ab7;
}
#stepCont .stepper-slide-1-enter-active {
    animation: slide-in .3s;
}
#stepCont .stepper-slide-1-leave-active {
     animation: slide-out .3s;
}
@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slide-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

#stepCont .stepper-slide-2-enter-active {
    animation: slide2-in .3s;
}
#stepCont .stepper-slide-2-leave-active {
    animation: slide2-out .3s;
}
@keyframes slide2-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slide2-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

@media screen and (max-width: 992px){
    #stepCont .input-container{
        width: 80%;
    }
}
</style>